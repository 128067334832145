<template>
	<div class="feedback-info-page">
		<van-cell-group>
			<van-cell :title="translate('info_title')" :value="name" />
			<van-cell :title="translate('info_detailed_description')" :value="description" />
			<van-cell :title="translate('info_state')" :value="status == 1 ? translate('info_to_be_processed') : translate('info_processed')" />
			<van-cell :title="translate('process_result')" :value="reply" v-if="status != 1" />
		</van-cell-group>		
	</div>
</template>

<script>
	export default {
		name: 'feedback_info',
		data() {
			return {
				formItem: {
					id: "",
					token: "",
				},
				
				name: "",
				description: "",
				reply: "",
				date: "",
				status: "",
			}
		},

		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.formItem.id = this.$route.query.id
				
				this.loadData()
			}
			
		},
		methods: {
			loadData() {
				this.$toast.loading({
					message: this.translate('info_loading'),
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'feedback/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.name = res.data.name
						this.description = res.data.description
						this.reply = res.data.reply
						this.date = res.data.date
						this.status = res.data.status
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
		}
	}
</script>